exports.components = {
  "component---src-pages-404-jsx": () => import("./../../../src/pages/404.jsx" /* webpackChunkName: "component---src-pages-404-jsx" */),
  "component---src-pages-aditya-jsx": () => import("./../../../src/pages/aditya.jsx" /* webpackChunkName: "component---src-pages-aditya-jsx" */),
  "component---src-pages-contact-jsx": () => import("./../../../src/pages/contact.jsx" /* webpackChunkName: "component---src-pages-contact-jsx" */),
  "component---src-pages-cristian-jsx": () => import("./../../../src/pages/cristian.jsx" /* webpackChunkName: "component---src-pages-cristian-jsx" */),
  "component---src-pages-david-jsx": () => import("./../../../src/pages/david.jsx" /* webpackChunkName: "component---src-pages-david-jsx" */),
  "component---src-pages-index-jsx": () => import("./../../../src/pages/index.jsx" /* webpackChunkName: "component---src-pages-index-jsx" */),
  "component---src-pages-johana-jsx": () => import("./../../../src/pages/johana.jsx" /* webpackChunkName: "component---src-pages-johana-jsx" */),
  "component---src-pages-partners-jsx": () => import("./../../../src/pages/partners.jsx" /* webpackChunkName: "component---src-pages-partners-jsx" */),
  "component---src-pages-portafolio-jsx": () => import("./../../../src/pages/portafolio.jsx" /* webpackChunkName: "component---src-pages-portafolio-jsx" */),
  "component---src-pages-ricardo-jsx": () => import("./../../../src/pages/ricardo.jsx" /* webpackChunkName: "component---src-pages-ricardo-jsx" */),
  "component---src-pages-who-jsx": () => import("./../../../src/pages/who.jsx" /* webpackChunkName: "component---src-pages-who-jsx" */)
}

